/* Cyberpunk Flow Lab - Main Stylesheet */

@import url('https://fonts.googleapis.com/css2?family=Rajdhani:wght@300;400;500;600;700&family=Share+Tech+Mono&display=swap');

/* General Styles */
body {
  font-family: 'Rajdhani', sans-serif;
  margin: 0;
  padding: 0;
  background-color: #0a0b1a;
  color: #e0e0ff;
  overflow-x: hidden;
  background-image: 
    linear-gradient(0deg, rgba(10, 11, 26, 0.9) 0%, rgba(10, 11, 26, 0.95) 100%),
    url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%231e3f88' fill-opacity='0.15'%3E%3Cpath d='M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2v-4h4v-2H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}

/* Typography */
h1, h2, h3, h4 {
  font-family: 'Rajdhani', sans-serif;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #00fffc;
  text-shadow: 0 0 10px rgba(0, 255, 252, 0.7);
}

/* Glitch effect for headings */
h2 {
  position: relative;
  font-size: 2.8rem;
  margin-bottom: 30px;
}

h2:before, h2:after {
  content: attr(data-text);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

h2:before {
  left: 2px;
  text-shadow: -1px 0 #ff00ff;
  animation: glitch-1 2s linear infinite alternate-reverse;
}

h2:after {
  left: -2px;
  text-shadow: 2px 0 #00fffc;
  animation: glitch-2 3s linear infinite alternate-reverse;
}

@keyframes glitch-1 {
  0%, 80%, 100% { clip-path: inset(0 0 0 0); }
  20% { clip-path: inset(8% 0 13% 0); }
  40% { clip-path: inset(33% 0 25% 0); }
  60% { clip-path: inset(54% 0 5% 0); }
}

@keyframes glitch-2 {
  0%, 80%, 100% { clip-path: inset(0 0 0 0); }
  20% { clip-path: inset(15% 0 25% 0); }
  40% { clip-path: inset(8% 0 40% 0); }
  60% { clip-path: inset(50% 0 12% 0); }
}

.header {
  background-color: rgba(10, 11, 26, 0.8);
  border-bottom: 2px solid #ff00ff;
  color: #ffffff;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  box-shadow: 0 4px 20px rgba(255, 0, 255, 0.4);
  width: 100%;
  box-sizing: border-box;
  backdrop-filter: blur(5px);
  position: sticky;
  top: 0;
  z-index: 100;
}

.header-logo {
  height: 50px;
  filter: hue-rotate(300deg) brightness(1.2) drop-shadow(0 0 8px rgba(255, 0, 255, 0.7));
}

.main-logo {
  font-size: 1.5rem;
  font-weight: 600;
  height: 15rem;
  padding: 20px;
  padding-bottom: 50px;
  filter: hue-rotate(300deg) brightness(1.2) drop-shadow(0 0 15px rgba(0, 255, 252, 0.8));
  animation: pulse 3s infinite alternate;
}

@keyframes pulse {
  0% { opacity: 0.8; }
  100% { opacity: 1; filter: hue-rotate(300deg) brightness(1.2) drop-shadow(0 0 25px rgba(0, 255, 252, 0.8)); }
}

.nav {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.nav a {
  color: #00fffc;
  text-decoration: none;
  font-weight: 600;
  text-transform: uppercase;
  position: relative;
  transition: color 0.3s;
  padding: 5px 0;
  font-family: 'Share Tech Mono', monospace;
  letter-spacing: 1px;
}

.nav a:before {
  content: "";
  position: absolute;
  width: 0;
  height: 2px;
  bottom: 0;
  left: 0;
  background: linear-gradient(90deg, #ff00ff, #00fffc);
  transition: width 0.3s ease;
}

.nav a:hover {
  color: #ffffff;
}

.nav a:hover:before {
  width: 100%;
}

/* Scanner line effect */
.nav a:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  background: #00fffc;
  left: 0;
  bottom: 0;
  transform: scaleX(0);
  transform-origin: left;
  animation: scan 2s infinite;
  opacity: 0.7;
}

@keyframes scan {
  0% { transform: scaleX(0); opacity: 0.7; }
  50% { transform: scaleX(1); opacity: 0.3; }
  100% { transform: scaleX(0); opacity: 0.7; }
}

.hero {
  background: linear-gradient(135deg, rgba(10, 11, 26, 0.8) 0%, rgba(30, 58, 138, 0.8) 100%);
  color: #ffffff;
  text-align: center;
  padding: 120px 20px;
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
}

/* Digital grid overlay */
.hero:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: 
    linear-gradient(rgba(0, 255, 252, 0.1) 1px, transparent 1px),
    linear-gradient(90deg, rgba(0, 255, 252, 0.1) 1px, transparent 1px);
  background-size: 40px 40px;
  background-position: center center;
  z-index: 1;
}

/* Animated circuit lines */
.hero:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("data:image/svg+xml,%3Csvg width='100' height='100' viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 0h100v100H0z' fill='none'/%3E%3Cpath d='M10 10h80v80H10z' fill='none' stroke='%2300fffc' stroke-width='0.5' stroke-dasharray='5,5'/%3E%3Cpath d='M20 20h60v60H20z' fill='none' stroke='%23ff00ff' stroke-width='0.5' stroke-dasharray='3,3'/%3E%3C/svg%3E");
  background-size: 200px 200px;
  opacity: 0.2;
  z-index: 1;
  animation: circuitMove 20s linear infinite;
}

@keyframes circuitMove {
  0% { background-position: 0 0; }
  100% { background-position: 200px 200px; }
}

.hero h1 {
  font-size: 3.5rem;
  margin-bottom: 20px;
  position: relative;
  z-index: 2;
  color: #00fffc;
  text-shadow: 0 0 15px rgba(0, 255, 252, 0.7);
}

.hero p {
  font-size: 1.4rem;
  max-width: 800px;
  margin: 0 auto 40px;
  line-height: 1.6;
  position: relative;
  z-index: 2;
  text-shadow: 0 0 10px rgba(255, 0, 255, 0.7);
}

.cta-button {
  padding: 15px 30px;
  text-decoration: none;
  font-weight: 600;
  border-radius: 0;
  border: 2px solid #ff00ff;
  background-color: transparent;
  color: #ff00ff;
  text-transform: uppercase;
  letter-spacing: 2px;
  position: relative;
  z-index: 2;
  overflow: hidden;
  transition: color 0.3s;
  font-family: 'Share Tech Mono', monospace;
  box-shadow: 0 0 15px rgba(255, 0, 255, 0.5);
}

.cta-button:before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, transparent, rgba(255, 0, 255, 0.4), transparent);
  transition: left 0.7s;
}

.cta-button:hover {
  color: #00fffc;
  border-color: #00fffc;
  box-shadow: 0 0 20px rgba(0, 255, 252, 0.7);
}

.cta-button:hover:before {
  left: 100%;
}

.content-section {
  padding: 60px 20px;
  text-align: center;
  background-color: rgba(15, 17, 34, 0.8);
  box-shadow: 0 0 30px rgba(0, 255, 252, 0.3);
  margin: 40px auto;
  max-width: 1200px;
  border-radius: 0;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  border: 1px solid #00fffc;
  border-top: 1px solid #ff00ff;
  backdrop-filter: blur(5px);
}

/* Digital corner accents */
.content-section:before, .content-section:after {
  content: "";
  position: absolute;
  width: 40px;
  height: 40px;
  border-color: #ff00ff;
  border-style: solid;
  z-index: 0;
}

.content-section:before {
  top: 0;
  left: 0;
  border-width: 2px 0 0 2px;
}

.content-section:after {
  bottom: 0;
  right: 0;
  border-width: 0 2px 2px 0;
}

.content-section p {
  max-width: 800px;
  margin: 0 auto 30px;
  font-size: 1.3rem;
  line-height: 1.6;
  position: relative;
  z-index: 2;
}

.content-section h2 {
  position: relative;
  z-index: 2;
  display: inline-block;
  margin-bottom: 40px;
}

.services {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 30px;
  position: relative;
  z-index: 2;
}

.service {
  background-color: rgba(30, 58, 138, 0.3);
  padding: 30px;
  border: 1px solid #00fffc;
  width: 100%;
  max-width: 280px;
  border-radius: 0;
  box-shadow: 0 0 20px rgba(0, 255, 252, 0.2);
  transition: transform 0.3s, box-shadow 0.3s;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
}

/* Tech corner details */
.service:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 30px 30px 0;
  border-color: transparent #ff00ff transparent transparent;
}

.service h3 {
  color: #00fffc;
  margin-bottom: 15px;
}

.service:hover {
  transform: translateY(-10px);
  box-shadow: 0 0 30px rgba(255, 0, 255, 0.4);
  border-color: #ff00ff;
}

.customers {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 30px;
  position: relative;
  z-index: 2;
}

.customer {
  padding: 40px;
  border-radius: 0;
  border: 1px solid #00fffc;
  box-shadow: 0 0 20px rgba(0, 255, 252, 0.2);
  transition: transform 0.3s, box-shadow 0.3s;
  width: 100%;
  max-width: 400px;
  box-sizing: border-box;
  background-color: rgba(30, 58, 138, 0.3);
  position: relative;
}

.customer:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background: linear-gradient(90deg, #ff00ff, transparent);
}

.customer-img {
  width: 100%;
  height: auto;
  margin-bottom: 20px;
  filter: hue-rotate(180deg) saturate(2) brightness(1.2) drop-shadow(0 0 5px rgba(0, 255, 252, 0.7));
  transition: filter 0.3s;
}

.customer:hover {
  transform: translateY(-10px);
  box-shadow: 0 0 30px rgba(255, 0, 255, 0.4);
  border-color: #ff00ff;
}

.customer:hover .customer-img {
  filter: hue-rotate(300deg) saturate(2) brightness(1.5) drop-shadow(0 0 10px rgba(255, 0, 255, 0.8));
}

.footer {
  background-color: rgba(10, 11, 26, 0.9);
  color: #ffffff;
  text-align: center;
  padding: 40px 20px;
  margin-top: 60px;
  box-sizing: border-box;
  border-top: 2px solid #ff00ff;
  position: relative;
  overflow: hidden;
}

.footer:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: 
    linear-gradient(rgba(0, 255, 252, 0.05) 1px, transparent 1px),
    linear-gradient(90deg, rgba(0, 255, 252, 0.05) 1px, transparent 1px);
  background-size: 20px 20px;
  z-index: 0;
}

.footer a {
  color: #ff00ff;
  text-decoration: none;
  font-weight: 600;
  transition: color 0.3s, text-shadow 0.3s;
  position: relative;
  z-index: 2;
}

.footer a:hover {
  color: #00fffc;
  text-shadow: 0 0 10px rgba(0, 255, 252, 0.8);
}

.footer p {
  position: relative;
  z-index: 2;
}

/* Address section styling */
.address {
  position: relative;
  z-index: 2;
}

.address p {
  font-family: 'Share Tech Mono', monospace;
  letter-spacing: 1px;
}

.address a {
  color: #ff00ff;
  text-decoration: none;
  transition: color 0.3s, text-shadow 0.3s;
  border-bottom: 1px dashed #ff00ff;
  padding-bottom: 2px;
}

.address a:hover {
  color: #00fffc;
  text-shadow: 0 0 8px rgba(0, 255, 252, 0.8);
  border-bottom-color: #00fffc;
}

/* Terminal typing effect for About section */
.terminal-text {
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  font-family: 'Share Tech Mono', monospace;
  border-right: 0.15em solid #ff00ff;
  animation: typing 3.5s steps(50, end), blink-caret 0.75s step-end infinite;
  margin: 0 auto;
  text-align: left;
}

@keyframes typing {
  from { width: 0 }
  to { width: 100% }
}

@keyframes blink-caret {
  from, to { border-color: transparent }
  50% { border-color: #ff00ff }
}

/* Loading bar effect */
.loading-bar {
  height: 4px;
  width: 100%;
  position: relative;
  overflow: hidden;
  background-color: rgba(0, 255, 252, 0.1);
  margin: 20px 0;
}

.loading-bar:before {
  display: block;
  position: absolute;
  content: "";
  left: -200px;
  width: 200px;
  height: 4px;
  background-color: #ff00ff;
  animation: loading 2s linear infinite;
}

@keyframes loading {
  from { left: -200px; width: 30%; }
  50% { width: 30%; }
  70% { width: 70%; }
  80% { left: 50%; }
  95% { left: 120%; }
  to { left: 100%; }
}

/* Media Queries for Mobile Devices */
@media (max-width: 768px) {
  .header {
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
  }

  .header-logo {
    height: 30px;
  }
  
  .main-logo {
    height: 8rem;
    padding-bottom: 20px;
  }
  
  .nav {
    width: 100%;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 10px;
  }
  
  .nav a {
    margin: 5px 10px;
    font-size: 1rem;
  }
  
  .hero {
    padding: 80px 20px;
  }
  
  .hero h1 {
    font-size: 2.5rem;
  }
  
  .hero p {
    font-size: 1.2rem;
    line-height: 1.4;
  }
  
  .content-section {
    padding: 40px 20px;
  }
  
  .content-section h2 {
    font-size: 2rem;
  }
  
  .content-section p {
    font-size: 1.1rem;
  }
  
  .services, .customers {
    flex-direction: column;
    align-items: center;
  }
  
  .service, .customer {
    width: 100%;
    max-width: 400px;
    margin-bottom: 20px;
  }

  .customer-img {
    width: 100%;
    height: auto;
  }
}

@media (max-width: 480px) {
  .hero {
    padding: 60px 20px;
  }
  
  .hero h1 {
    font-size: 2rem;
  }
  
  .hero p {
    font-size: 1rem;
  }
  
  .cta-button {
    padding: 10px 20px;
    font-size: 1rem;
  }
  
  .content-section {
    padding: 30px 20px;
  }
  
  .content-section h2 {
    font-size: 1.8rem;
  }
  
  .content-section p {
    font-size: 1rem;
  }

  .nav a {
    font-size: 0.9rem;
  }
}

/* Cyberpunk-specific animations */
/* Flicker animation for text elements */
@keyframes flicker {
  0%, 100% { opacity: 1; }
  3% { opacity: 0.8; }
  6% { opacity: 0.9; }
  9% { opacity: 0.8; }
  11% { opacity: 0.9; }
  14% { opacity: 0.8; }
  20% { opacity: 1; }
  50% { opacity: 0.9; }
  70% { opacity: 1; }
}

.hero h1, .hero p {
  animation: flicker 5s linear infinite;
}

/* Scanning line effect for sections */
.content-section {
  position: relative;
}

.content-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background: linear-gradient(to right, transparent, #ff00ff, transparent);
  opacity: 0.7;
  animation: scanline 3s linear infinite;
  z-index: 1;
}

@keyframes scanline {
  0% { transform: translateY(-100px); }
  100% { transform: translateY(800px); }
}

/* Cyberpunk glitch cursor */
.cyber-cursor {
  display: inline-block;
  width: 10px;
  height: 20px;
  background-color: #ff00ff;
  animation: blink 1s infinite;
  vertical-align: middle;
  margin-left: 5px;
}

@keyframes blink {
  0%, 49% { opacity: 1; }
  50%, 100% { opacity: 0; }
}